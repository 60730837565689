.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.familytree {
    padding-top: 5vh;
    max-width: 60vw;
    min-width: 30vw;
}

.familytree:hover {
    opacity: 0.3;
}

.member-avatar:hover {
    background-color: orange;
    color: black;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

/* @media (prefers-reduced-motion: no-preference) { */
/*   .App-logo { */
/*     /\* animation-duration: 2s; *\/ */
/*     /\* animation-name: App-logo-fall; *\/ */
/*     /\* animation-iteration-count: infinite; *\/ */
/*     /\* animation-direction: alternate; *\/ */

/*     animation: jump 3s infinite; */
/*   } */
/* } */


/*  @keyframes jump { */
/*      0% {top: 0;animation-timing-function: ease-in;} */
/*      50% {top: 140px;height: 140px;animation-timing-function: ease-out;} */
/*      55% {top: 160px; height: 120px;border-radius: 70px / 60px;animation-timing-function: ease-in;} */
/*      65% {top: 120px; height: 140px;border-radius: 70px;animation-timing-function: ease-out;} */
/*      95% {top: 0;animation-timing-function: ease-in;} */
/*      100% {top: 0;animation-timing-function: ease-in;} */
/* } */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* @keyframes App-logo-fall { */
/*     from { */
/*         margin-top: 0%; */
/*         width: 100%; */
/*     } */
/*     to { */
/*         margin-top: 100%; */
/*         width: 100%; */
/*     } */
/* } */

/* @keyframes App-logo-spin { */
/*   from { */
/*     transform: rotate(0deg); */
/*   } */
/*   to { */
/*     transform: rotate(60deg); */
/*   } */
/* } */
